<template>
  <v-app>
    <!-- <HeaderEncuesta/> -->
    <v-main >
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import HeaderEncuesta from '@/modules/encuesta/components/HeaderEncuesta.vue'
export default {
  name: 'App',

  data: () => ({
    //
  }),
  components:{
    HeaderEncuesta
  }
};
</script>
