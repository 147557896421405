<template>
  <v-container class="d-flex">
    <v-spacer></v-spacer>
    <img width="250" src="@/assets/logo_red_medica.png" />
    <v-spacer></v-spacer>
  </v-container>
</template>

<script>
export default {};
</script>
